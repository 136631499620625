/** @format */
import {
  DialogService
} from 'aurelia-dialog';
import {
  Router
} from 'aurelia-router';
import {
  NewInstance,
  computedFrom,
  bindable
} from 'aurelia-framework';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';
import poiService, {
  POICollection
} from 'services/api/areasService';

import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';

export class AdminPoiCollectionDetails {
  static inject() {
    return [Router, NewInstance.of(ValidationController), DialogService];
  }

  @bindable id;
  @bindable onClose;

  constructor(_Router, _ValidationController, _DialogService) {
    this.router = _Router;
    this.validationController = _ValidationController;
    //
    this.poiService = poiService;
    //
    this.dialogService = _DialogService;
    this.collection = [];

    this.pois = [];
    this.selectedPois = [];

    this.loading = false;
    this.errorMessage = null;

    this.rules = ValidationRules.ensure(d => d.name).required().rules;

    this.onSave = this.onSave.bind(this);

    this.onPoiListChanged = this.onPoiListChanged.bind(this);
  }

  @computedFrom('collection')
  get collectionPoints() {
    if (!this.collection) return [];

    return (this.collection.points || []).map(p => p.id);
  }

  attached() {
    this.collection = null;
    this.id = null;
  }

  idChanged(value) {
    this.loadData(value);
  }

  loadData(id) {
    this.collection = new POICollection();

    this.poiService.get().then(pois => (this.pois = pois));

    if (id > 0) {
      this.loading = true;
      this.poiService
        .getCollections(id)
        .then(item => {
          this.collection = item;
          this.loading = false;
        })
        .catch(() => this.navigateBack());
    }
  }

  onCancel() {
    this.navigateBack();
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      //api not handling '-1' for all
      const all = this.selectedPois[0] === -1;
      this.collection.points = all ?
        this.pois.map(p => p.id) :
        this.selectedPois;

      return this.poiService
        .updateCollection(this.collection)
        .then(() => {
          this.loading = false;
          this.navigateBack();
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onDelete() {
    this.openPrompt().then(confirm => {
      if (!confirm) return;
      let collection = this.collection;
      this.loading = true;

      this.poiService
        .deleteCollection(collection.id)
        .then(() => {
          this.collection.deleted = true;

          this.loading = false;
          this.navigateBack();
        })
        .catch(e => {
          this.err = e.message;
          this.loading = false;
        });
    });
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.collection,
        rules: this.rules
      })
      .then(result => {
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  navigateBack() {
    this.onClose(this.collection);
  }

  onPoiListChanged(pois) {
    this.selectedPois = pois.map(m => m.id);
  }

  openPrompt() {
    const title = 'delete_poi_collection';
    const question = true;
    const message = 'delete_poi_collection_message';
    const confirm = 'delete';

    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }
}
