/** @format */

import {
  inject,
  NewInstance,
  computedFrom
} from 'aurelia-framework';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';

import uiService, {
  DEFAULT_THEME
} from 'services/uiService';

import userService, {
  RulesInvertalUnits
} from 'services/api/userService';
import eventService, {
  EventsList
} from 'services/eventService';
import {
  JourneyLabels
} from 'services/api/telemetryService';

import {
  DialogService
} from 'aurelia-dialog';
import {
  AdminProfileDlc
} from './admin-profile-dlc/admin-profile-dlc';
import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';
import './admin-profile-page.scss';

@inject(NewInstance.of(ValidationController), DialogService)
export class AdminProfilePage {
  selectedAuthMethod;

  constructor(_ValidationController, _DialogService) {
    this.uiService = uiService;
    this.validationController = _ValidationController;
    this.dialogService = _DialogService;

    this.userService = userService;

    this.user = {};
    this.theme = {};
    this.rulesInvertalUnits = RulesInvertalUnits;
    this.DLCRenewalRules = {
      first: '6M',
      second: '3M',
      third: '1M'
    }
    this.minMaxValueRules = {
      'd': {
        min: '1',
        max: '366'
      },
      'M': {
        min: '1',
        max: '12'
      },
    };

    this.journeyLabels = JourneyLabels;

    this.loading = false;
    this.dlcResetAutomationPrompt = false;

    this.rules = ValidationRules.ensure(u => u.accountName).required().rules;
    this.errorMessage = null;

    this.onResetTheme = this.onResetTheme.bind(this);
    this.onThemeBgColorChange = this.onThemeBgColorChange.bind(this);
    this.onThemeTextColorChange = this.onThemeTextColorChange.bind(this);
  }

  get accountTheme() {
    if (this.user && this.user.accountSettings && this.user.accountSettings.theme) {
      return {
        ...this.user.accountSettings.theme
      };
    }
  }

  @computedFrom('user.countryId')
  get showDLC() {
    return this.user.countryId === 'GB';
  }

  @computedFrom('user.accountSettings')
  get DLCSettings() {
    if (!this.user || !this.user.accountSettings || !this.user.accountSettings.ex_thirdparty_dlc) return false;
    return this.user.accountSettings.ex_thirdparty_dlc;
  }

  @computedFrom('DLCSettings')
  get DLCEnabled() {
    return this.DLCSettings && this.DLCSettings.isEnabled;
  }

  @computedFrom('DLCSettings.properties')
  get renewalRules() {
    if (!this.DLCEnabled) return null;
    if (this.DLCSettings.properties.automaticRenewals) {
      if (!this.DLCSettings.properties.renewalRules || this.DLCSettings.properties.renewalRules.length < 3) {
        this.DLCSettings.properties.renewalRules = ['6M', '3M', '1M'];
      }
      return this.DLCSettings.properties.renewalRules;
    }

    return null;
  }

  @computedFrom('user.accountSettings.personalJourneys')
  get personalJourneysStatus() {
    return (this.user.accountSettings && this.user.accountSettings.personalJourneys === 'driverOnly') ? true : false;
  }

  set personalJourneysStatus(value) {
    this.user.accountSettings.personalJourneys = (value) ? 'driverOnly' : 'everyone';
  }

  attached() {
    this.userService.getSelf(true).then(user => {
      this.user = user;
      if (this.renewalRules && this.renewalRules.length) {
        if (this.renewalRules[0]) {
          this.DLCRenewalRules.first = this.renewalRules[0];
        }
        if (this.renewalRules[1]) {
          this.DLCRenewalRules.second = this.renewalRules[1];
        }
        if (this.renewalRules[2]) {
          this.DLCRenewalRules.third = this.renewalRules[2];
        }
      }
      this.theme = this.accountTheme;
    });
  }

  detached() {
    let theme = this.accountTheme;
    this.broadcastTheme(theme);
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      if (this.DLCSettings?.properties?.automaticRenewals) {
        if (this.DLCRenewalRules.first != this.renewalRules[0] || this.DLCRenewalRules.second != this.renewalRules[1] || this.DLCRenewalRules.third != this.renewalRules[2]) {
          this.dlcResetAutomationPrompt = true;
        }
        let rules = [this.DLCRenewalRules.first, this.DLCRenewalRules.second, this.DLCRenewalRules.third];
        this.DLCSettings.properties.renewalRules = rules;
      } else if (this.DLCSettings?.properties) {
        delete this.DLCSettings.properties.renewalRules;
      }

      const settings = this.user.accountSettings;
      settings.theme = this.theme;

      this.userService
        .updateAccount({
          id: this.user.accountId,
          settings: settings
        })
        .then(() => {
          this.loading = false;
          if (this.dlcResetAutomationPrompt) {
            this.dlcResetAutomationPrompt = false;
            this.openResetAutomationDialog();
          }
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.user,
        rules: this.rules
      })
      .then(result => {
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  openResetAutomationDialog() {
    this.dialogService
      .open({
        viewModel: PromptDialog,
        model: {
          title: 'dlc-reset-automation',
          question: false,
          message: 'dlc-reset-automation-message',
          confirm: 'apply'
        }
      })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          this.loading = true;
          this.userService.resetAutomation().then(result => {
            this.loading = false;
          }).catch(err => {
            this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
            this.loading = false;
          });
        }
      });
  }

  openDLCDialog() {
    this.dialogService.open({
      viewModel: AdminProfileDlc
    }).whenClosed(result => {
      if (!result.wasCancelled) {
        this.user.accountSettings = result.output;
      }
    });
  }

  //TODO: if anymore are added, refactor into <theme-editor> compont.
  onThemeBgColorChange(backgroundColor) {
    this.broadcastTheme({
      backgroundColor
    });
  }

  onThemeTextColorChange(textColor) {
    this.broadcastTheme({
      textColor
    });
  }

  onResetTheme() {
    this.broadcastTheme({
      ...DEFAULT_THEME
    });
  }

  broadcastTheme(theme) {
    this.theme = {
      ...this.theme,
      ...theme
    };
    eventService.publish(EventsList.AppThemePreviewChanged, this.theme);
  }

  onAutomaticRenewalsChanged(event) {
    this.dlcResetAutomationPrompt = (this.DLCSettings.properties.automaticRenewals) ? true : false;
  }

  mergeValueUnits(valueUnit) {
    return (valueUnit.value) ? valueUnit.value + valueUnit.unit : null;
  }

  onDLCRenewalFirstRuleChanged(event) {
    if (event.detail) {
      this.DLCRenewalRules.first = this.mergeValueUnits(event.detail)
    }
  }

  onDLCRenewalSecondRuleChanged(event) {
    if (event.detail) {
      this.DLCRenewalRules.second = this.mergeValueUnits(event.detail)
    }
  }

  onDLCRenewalThirdRuleChanged(event) {
    if (event.detail) {
      this.DLCRenewalRules.third = this.mergeValueUnits(event.detail)
    }
  }
}
