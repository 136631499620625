import {
  inlineView
} from 'aurelia-framework'
import PLATFORM from 'aurelia-pal';

import './admin-reminders-page.scss';

@inlineView('<template><router-view containerless></router-view></template>')
export class AdminRemindersPage {

  configureRouter(config, router) {
    config.map([{
      route: '',
      name: 'admin-reminders',
      title: '',
      moduleId: PLATFORM.moduleName('./admin-reminders-list')
    }, {
      route: '/set/:id',
      name: 'admin-reminders-set',
      title: 'Reminder set details',
      moduleId: PLATFORM.moduleName('./admin-reminders-set-list')
    }, {
      route: ':id',
      name: 'admin-reminder-details',
      title: 'Reminder details',
      moduleId: PLATFORM.moduleName('./admin-reminder-details')
    }])
  }
}
