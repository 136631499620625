import machinesService, { Machine } from 'services/api/machinesService';

export class AdminMachinesList {
  constructor() {
    this.machinesService = machinesService;

    this.gridHeaders = [
      { label: 'name', sortable: true },
    ];
    this.gridColumns = [
      { type: 'text', field: 'name' },
    ]

    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.machines = [];
    this.loading = false;
    
    this.onMachineSelected = this.onMachineSelected.bind(this);
    this.onNewMachine = this.onNewMachine.bind(this);
  }

  activate(params, config) {
    this.route = config;
    this.router = this.route.navModel.router;
    this.load()
  }

  load() {
    this.loading = true;
    this.machinesService.get().then(machines => {
      this.machines = machines;
      this.loading = false
    })
  }

  onMachineSelected(machine) {
    this.goToDetails(machine)
  }

  onNewMachine() {
    this.goToDetails(new Machine());
  }

  goToDetails(machine) {
    let route = 'admin-machine-details'
    this.router.routes.find(x => x.name === route).settings = { machine };
    this.router.navigateToRoute(route, {id: machine.id || 'new' })
  }
}
