/** @format */

import {
  NewInstance,
  bindable
} from 'aurelia-framework';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';
import {
  DialogService
} from 'aurelia-dialog';
import {
  Router
} from 'aurelia-router';

import poiService from 'services/api/areasService';
import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';

export class AdminPoiDetails {
  static inject() {
    return [Router, NewInstance.of(ValidationController), DialogService];
  }
  @bindable id;
  @bindable onClose;

  constructor(_Router, _ValidationController, _DialogService) {
    this.router = _Router;
    this.validationController = _ValidationController;
    //
    this.poiService = poiService;
    //
    this.dialogService = _DialogService;
    this.poi = null;
    this.loading = true;
    this.errorMessage = null;

    this.rules = ValidationRules.ensure(d => d.name).required().rules;

    this.onSave = this.onSave.bind(this);
  }

  idChanged(value) {
    if (value) {
      this.loadPOI(value);
    }
  }

  loadPOI(id) {
    if (id > 0) {
      this.loading = true;
      this.poiService
        .get(id)
        .then(poi => {
          this.poi = poi;
          this.loading = false;
        })
        .catch(() => this.navigateBack());
    }
  }

  onCancel() {
    this.navigateBack();
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      this.poiService
        .update(this.poi)
        .then(poi => {
          this.poi = poi;
          this.loading = false;
          this.navigateBack();
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onDelete() {
    this.openPrompt().then(confirm => {
      if (!confirm) return;
      let poi = this.poi;
      this.loading = true;

      this.poiService
        .delete(poi)
        .then(() => {
          this.poi.deleted = true;
          this.loading = false;
          this.navigateBack();
        })
        .catch(e => {
          this.err = e.message;
          this.loading = false;
        });
    });
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.poi,
        rules: this.rules
      })
      .then(result => {
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  navigateBack() {
    this.onClose();
  }

  openPrompt() {
    const title = 'delete_poi';
    const question = true;
    const message = 'delete_poi_message';
    const confirm = 'delete';

    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }
}
