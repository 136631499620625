/** @format */

import poiService from 'services/api/areasService';
import { bindable } from 'aurelia-framework';

import { DialogService } from 'aurelia-dialog';
import { PoiCollectionShareDialog } from './poi-collection-share-dialog/poi-collection-share-dialog';

import './admin-poi-collections.scss';

export class AdminPoiCollections {
  @bindable route;

  static inject() {
    return [DialogService];
  }

  constructor(_DialogService) {
    this.poiService = poiService;
    this.dialogService = _DialogService;

    this.loading = false;
    this.collections = [];
    this.selectedId = null;

    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.onItemSelect = this.onItemSelect.bind(this);
    this.onDetailsClose = this.onDetailsClose.bind(this);
  }

  attached() {
    this.loadCollections();
  }

  detached() {
    this.collections = [];
    this.selectedId = null;
  }

  loadCollections() {
    this.loading = true;
    this.poiService.getCollections().then(items => {
      this.collections = items.map(i => ({ ...i, icon: 'fi-folder_shared' }));
      this.loading = false;
    });
  }

  onItemSelect(item, i, e) {
    if (e && e.target) {
      let action = e.target.dataset && e.target.dataset.action;

      if (action === 'share') {
        this.openShareDialog(item);
        return;
      }
    }

    this.goToDetails(item);
  }

  openShareDialog(item) {
    //todo
    this.dialogService
      .open({ viewModel: PoiCollectionShareDialog, model: item })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          this.loadCollections();
        }
      });
  }

  goToDetails(item) {
    this.selectedId = item && item.id;
  }

  onDetailsClose() {
    this.loadCollections();
    this.selectedId = null;
  }

  onNew() {
    this.selectedId = -1;
  }
}
