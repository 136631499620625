import {
  inject,
  bindable
} from 'aurelia-framework';
import remindersService, {
  ReminderTypes
} from 'services/api/remindersService';
import userService from 'services/api/userService';
import {
  TranslationService
} from 'services/translationService';

const ID_ALL = 0;

@inject(TranslationService)
export class ReminderFilters {
  @bindable selectedType;
  @bindable selectedAssets;
  @bindable task;
  @bindable loading;

  constructor(_TranslationService) {
    this.ts = _TranslationService;
    this.userService = userService;

    this.loading = false;
    this.task = '';
    this.selectedType = null;
    this.reminderTypes = [{
      id: 'all',
      value: ID_ALL,
      name: this.ts.getCap('all'),
      disabled: false,
      default: false,
      selected: true
    }];

    for (let type in ReminderTypes) {
      let item = ReminderTypes[type];
      this.reminderTypes.push({
        id: type,
        value: type,
        name: this.ts.getCap(item)
      });
    }

    this.onReminderTypeChanged = this.onReminderTypeChanged.bind(this);
  }

  onReminderTypeChanged(type) {
    this.selectedType = (type && type.value != ID_ALL) ? type.value : null
  }

  selectedTypeChanged() {
    let selectedType = this.selectedType;
    if (this.reminderTypes) {
      this.reminderTypes = this.reminderTypes.map(t => {
        if (selectedType && selectedType == t.value) {
          t.selected = true;
        } else {
          t.selected = false;
        }
        return t;
      })
    }
  }
}
