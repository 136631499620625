import { inlineView } from 'aurelia-framework'
import PLATFORM from 'aurelia-pal';

@inlineView('<template><router-view containerless></router-view></template>')

export class AdminMachinesPage {
  configureRouter(config,router) {
    config.map([{
      route: '',
      name: 'admin-machines',
      title: '',
      moduleId: PLATFORM.moduleName('./admin-machines-list')
      }
      ,{
        route: ':id',
        name: 'admin-machine-details',
        title: 'API Details',
        moduleId: PLATFORM.moduleName('./admin-machine-details')
      }
    ])
  }
}
