/** @format */

import {
  computedFrom,
  NewInstance
} from 'aurelia-framework';
import {
  ValidationRules,
  ValidationController,
  validateTrigger
} from 'aurelia-validation';
import {
  Router
} from 'aurelia-router';
import {
  DialogService
} from 'aurelia-dialog';

import machinesService from 'services/api/machinesService';
import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';
import './admin-machine-details.scss';

export class AdminMachineDetails {
  static inject() {
    return [Router, NewInstance.of(ValidationController), DialogService];
  }
  constructor(_Router, _ValidationController, _DialogService) {
    this.router = _Router;
    this.validationController = _ValidationController;
    this.validationController.validateTrigger = validateTrigger.manual;
    this.dialogService = _DialogService;
    //
    this.machinesService = machinesService;
    //
    this.machine = null;
    this.loading = false;
    this.errorMessage = null;

    this.loadingKeys = false;
    this.showCopyText = false;
    this.selectedKey = null;
    this.onKeyAction = this.onKeyAction.bind(this);

    this.rules = ValidationRules.ensure(d => d.name)
      .required()
      .minLength(5).withMessageKey('tooShort').rules;
  }

  @computedFrom('machine.keys')
  get machineKeys() {
    if (!this.machine) return [];
    return (this.machine.keys || []).map(m => ({
      id: m
    }));
  }

  @computedFrom('machineKeys')
  get maxKeysReached() {
    return this.machineKeys.length >= 2;
  }

  activate(params, cfg) {
    let {
      machine
    } = cfg.settings;

    if (!machine) {
      this.navigateBack();
      return;
    }

    this.machine = machine || {};
    this.loadMachines(params.id);
  }

  loadMachines(id) {
    if (!(id > 0)) return;

    this.loading = true;
    this.machinesService.get(id).then(machine => {
      this.machine = machine;
      this.loading = false;
    });
  }

  onKeyAction(key, action) {
    let _key = key.id;

    if (action === 'copy') {
      this.copyToClipboard(_key);
      return;
    }

    if (action === 'delete') {
      this.deleteKey(_key);
      return;
    }
  }

  onNewKey() {
    this.loadingKeys = true;
    this.machinesService
      .addKey(this.machine.id)
      .then(m => {
        this.machine = m;
        this.loadingKeys = false;
      })
      .catch(() => {
        this.loadingKeys = false;
      });
  }

  deleteKey(key) {
    this.loadingKeys = true;

    this.openDeletePrompt().then(confirm => {
      if (!confirm) return;

      this.machinesService.deleteKey(this.machine.id, key).then(machine => {
        this.loadingKeys = false;
        this.machine = machine;
      });
    });
  }

  openDeletePrompt() {
    const title = 'delete_machine_key';
    const question = true;
    const message = 'confirm_delete_machinekey';
    const confirm = 'delete';

    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }

  copyToClipboard(key) {
    try {
      var dummy = document.createElement('input');
      document.body.appendChild(dummy);
      dummy.setAttribute('id', 'dummy_id');
      dummy.setAttribute('value', key);
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);

      this.showCopyText = true;
      setTimeout(() => (this.showCopyText = false), 1000);
    } catch (e) {
      //
    }
  }

  onDelete() {
    this.openPrompt().then(confirm => {
      if (!confirm) return;
      this.loading = true;
      this.machinesService
        .delete(this.machine)
        .then(() => {
          this.navigateBack();
          this.loading = false;
        })
        .catch(() => {});
    });
  }

  onCancel() {
    this.navigateBack();
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);

      this.loading = true;
      return this.machinesService
        .update(this.machine)
        .then(_machine => {
          this.machine = _machine;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.machine,
        rules: this.rules
      })
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  navigateBack() {
    setTimeout(() => this.router.navigateToRoute('admin-machines'), 0);
  }

  openPrompt() {
    const title = 'delete_api';
    const question = true;
    const message = 'delete_api_message';
    const confirm = 'delete';

    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }
}
