/** @format */

import {
  NewInstance,
  computedFrom,
  observable
} from 'aurelia-framework';
import remindersService, {
  TimeIntervalUnits,
  ReminderTypes,
  ReminderConfigs,
  ReminderStatus
} from 'services/api/remindersService';
import {
  DialogService
} from 'aurelia-dialog';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';
import {
  Router
} from 'aurelia-router';
import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';
import {
  DateTimeUtils as DTU,
  ConvertUtils
} from '@fonix/web-utils';

export class AdminReminderDetails {
  static inject() {
    return [Router, DialogService, NewInstance.of(ValidationController)];
  }

  @observable measuringType;

  constructor(_Router, _DialogService, _ValidationController) {
    this.router = _Router;
    this.validationController = _ValidationController;
    this.dialogService = _DialogService;
    this.remindersService = remindersService;

    this.loading = false;
    this.errorMessage = null;

    this.accountUnit = ConvertUtils.getUnit('distance') || {
      name: 'km',
      weight: 0
    };

    this.reminderSet = null;
    this.reminderTypes = ReminderTypes;
    this.measuringTypes = {
      odometer: 'odometer',
      chronometer: 'chronometer'
    }
    this.measuringType = this.measuringTypes.odometer;
    this.timeIntervalUnits = TimeIntervalUnits;

    this.onTypeChanged = this.onTypeChanged.bind(this);
    this.onOwnerChanged = this.onOwnerChanged.bind(this);
    this.onRenewalDateChanged = this.onRenewalDateChanged.bind(this);
  }

  activate(params, cfg) {
    let {
      reminderSet
    } = cfg.settings;

    if (!reminderSet) {
      this.navigateBack();
      return;
    }

    if (reminderSet.id) {
      this.reminderSet = {
        id: reminderSet.id
      }
      this.loadReminderSet()
    } else {
      this.reminderSet = reminderSet;
      this.setReminderSet();
    }
  }

  loadReminderSet() {
    this.loading = true;
    this.remindersService.getReminderSet(this.reminderSet.id).then(reminderSet => {
      this.reminderSet = reminderSet;
      this.setReminderSet();
      this.loading = false;
    });
  }

  setReminderSet() {
    if (this.reminderSet.settings) {
      Object.defineProperty(this.reminderSet.settings, '_type', {
        enumerable: false,
        value: this.reminderSet.type
      });

      if (this.reminderSet.settings.mileageInterval) {
        this.measuringType = this.measuringTypes.odometer;
      }

      if (this.reminderSet.settings.usageInterval) {
        this.measuringType = this.measuringTypes.chronometer;
      }

      ValidationRules
        .ensure(d => d.description).required().minLength(3).withMessageKey('tooShort')
        .ensure(d => d.context).required().when(r => !r.id).withMessageKey('nocontextReminders')
        .ensure(d => d.channels).satisfies((value, object) => !value.filter(x => x.error).length).withMessageKey('novalidChannels')
        .ensure(d => d.settings).required().satisfies((r, o) => r.mileageInterval || r.usageInterval || r.timeInterval || r.renewalDate).withMessageKey('nosettings')
        .on(this.reminderSet);

      if (!this.reminderSet.id) {
        ValidationRules
          .ensure(d => d.type).required().withMessageKey('required')
          .ensure(d => d.ids).required().minItems(1).withMessageKey('nocontextReminders')
          .on(this.reminderSet.context);
      }

      this.onTypeChanged(this.reminderSet.type);
    }
  }

  @computedFrom('reminder.type')
  get configType() {
    if (this.reminderSet) {
      return ReminderConfigs[this.reminderSet.type];
    }
  }

  @computedFrom('reminder.status')
  get isResolved() {
    return this.reminderSet && this.reminderSet.status == ReminderStatus.resolved.id ? true : false
  }

  onTypeChanged(type) {
    if (type == ReminderTypes.renewal) {
      this.reminderSet.settings.isRecurring = false
    } else {
      this.reminderSet.settings.isRecurring = true
    }

    this.validationController.removeObject(this.reminderSet.settings);

    if (type == ReminderTypes.service) {
      if (this.measuringType == this.measuringTypes.odometer) {
        ValidationRules
          .ensure(d => d.timeInterval).required().withMessageKey('required').when(r => r.timeThreshold || !r.mileageInterval)
          .ensure(d => d.mileageInterval).required().withMessageKey('required').when(r => r.mileageThreshold || !r.timeInterval)
          .on(this.reminderSet.settings);
      } else if (this.measuringType == this.measuringTypes.chronometer) {
        ValidationRules
          .ensure(d => d.timeInterval).required().withMessageKey('required').when(r => r.timeThreshold || !r.usageInterval)
          .ensure(d => d.usageInterval).required().withMessageKey('required').when(r => r.usageThreshold || !r.timeInterval)
          .on(this.reminderSet.settings);
      }

    } else if (type == ReminderTypes.renewal) {

      ValidationRules
        .ensure(d => d.renewalDate).required().withMessageKey('required')
        .ensure(d => d.renewalDate).satisfies(r => !DTU.isBefore(r, DTU.add(DTU.today(), 1, 'day'))).when(r => {
          return (!this.reminderSet.id) ? true : false
        }).withMessageKey('date_after_today')
        .on(this.reminderSet.settings);

    }
  }

  measuringTypeChanged() {
    if (this.reminderSet) {
      this.onTypeChanged(this.reminderSet.type);
    }
  }

  onOwnerChanged(owner) {
    this.reminderSet.owner = owner;
  }

  onTimeIntervalChanged(event) {
    if (event.detail) {
      this.reminderSet.settings.timeInterval = this.mergeValueUnits(event.detail)
    }
  }

  onTimeThresholdChanged(event) {
    if (event.detail) {
      this.reminderSet.settings.timeThreshold = this.mergeValueUnits(event.detail)
    }
  }

  onRenewalThresholdChanged(event) {
    if (event.detail) {
      this.reminderSet.settings.renewalThreshold = this.mergeValueUnits(event.detail)
    }
  }

  mergeValueUnits(valueUnit) {
    return (valueUnit.value) ? valueUnit.value + valueUnit.unit : null;
  }

  onRenewalDateChanged(value) {
    this.reminderSet.settings.renewalDate = value;
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      this.reminderSet.settings.timeZone = DTU.detectTZ();
      if (!this.reminderSet.id) {
        return this.remindersService
          .createReminderSet(this.reminderSet)
          .then(reminderSet => {
            this.loading = false;
            this.reminderSet = reminderSet
            this.navigateBack(true);
          }).catch(err => {
            this.loading = false;
            throw (err);
          });
      } else {
        return this.remindersService
          .updateReminderSet(this.reminderSet)
          .then(reminderSet => {
            this.loading = false;
            this.reminderSet = reminderSet
            this.navigateBack(true);
          })
          .catch(err => {
            this.loading = false;
            throw (err);
          });
      }
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onDelete() {
    this.openPrompt().then(confirm => {
      if (!confirm) return;
      this.loading = true;
      this.remindersService
        .deleteReminderSet(this.reminderSet)
        .then(success => {
          if (success) {
            this.reminderSet.id = null;
            this.loading = false;
            this.navigateBack();
          }
        })
        .catch(err => {
          this.errorMessage = err.message;
          this.loading = false;
        });
    });
  }

  onCancel() {
    this.navigateBack();
  }

  onValidate() {
    return this.validationController
      .validate()
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  navigateBack(update = false) {
    if (this.reminderSet && this.reminderSet.id) {
      let route = 'admin-reminders-set';

      if (update) {
        this.router.routes.find(x => x.name === route).settings = {
          reminderSet: this.reminderSet
        };
      }

      setTimeout(() => this.router.navigateToRoute(route, {
        id: this.reminderSet.id
      }), 0);
    } else {
      setTimeout(() => this.router.navigateToRoute('admin-reminders'), 0);
    }
  }

  openPrompt() {
    const title = 'delete_reminders';
    const question = true;
    const message = 'delete_reminder_message';
    const confirm = 'delete';

    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }
}
