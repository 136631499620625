/** @format */

import { DialogController } from 'aurelia-dialog';

import poiService from 'services/api/areasService';
import userService from 'services/api/userService';

import './poi-collection-share-dialog.scss';

export class PoiCollectionShareDialog {
  static inject() {
    return [DialogController];
  }
  constructor(_DialogController) {
    this.dialogController = _DialogController;
    //
    this.poiService = poiService;
    this.userService = userService;
    //

    this.collection = null;
    this.loading = false;

    this.accounts = [];
    this.sharedAccounts = [];

    this.selectedAccounts = [];
    this.onListChanged = this.onListChanged.bind(this);
  }

  activate(model) {
    this.loading = true;

    this.collection = model;

    const qGetSelf = this.userService.getSelf();
    const qloadCollection = this.poiService.getCollections(model.id);

    qGetSelf.then(user => {
      const qloadAccounts = user.isSuperAdmin
        ? this.userService.getResellers()
        : this.userService.getAccounts();

      Promise.all([qloadCollection, qloadAccounts]).then(
        ([collection, accounts]) => {
          this.collection = collection;
          this.sharedAccounts = (collection.shared || []).map(a => a.accountId);
          this.accounts = accounts.filter(a => a.id !== user.accountId);
          this.loading = false;
        }
      );
    });
  }

  onListChanged(accounts) {
    this.selectedAccounts = accounts.map(m => m.id);
  }

  onCancel() {
    this.dialogController.cancel();
  }

  onConfirm() {
    this.loading = true;
    this.poiService
      .shareCollection(this.collection.id, this.selectedAccounts)
      .then(() => {
        this.dialogController.ok();
      })
      .catch(() => {
        //handle error
      });
  }
}
