import { bindable } from 'aurelia-framework';
import uiService from 'services/uiService';

import './poi-map.scss';

export class PoiMap {
  @bindable poi;

  constructor() {
    this.uiService = uiService;
    
    this.mapProvider = this.uiService.mapProvider;
    this.mapShapes = null;

    this.onMapReady = this.onMapReady.bind(this);

    this.qMapReady = new Promise(resolve => {
      this.qMapResolve = resolve;
    });
  }

  poiChanged(poi, oldPoi) {
    if (poi && oldPoi && poi.id === oldPoi.id) return;

    if (poi && poi.geometry) {
      this.qMapReady.then(() => {
        this.mapShapes = [this.poi.geometry];
      });
    }
  }

  onMapReady() {
    this.qMapResolve();
  }
}
