/** @format */

import { inject, computedFrom } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import userService from 'services/api/userService';

import LOG from 'services/loggerServices';

@inject(DialogController)
export class AdminProfileDlc {
  constructor(_DialogController) {
    this.dialogController = _DialogController;
    this.userService = userService;

    this.contactName = null;
    this.postCode = null;
    this.accountName = null;
  }

  @computedFrom('loading', 'accountName', 'postCode', 'contactName')
  get disableConfirm() {
    return (
      !!this.loading || !this.accountName || !this.postCode || !this.contactName
    );
  }

  onConfirm() {
    this.loading = true;
    this.userService
      .requestDLC({
        contactName: this.contactName,
        postCode: this.postCode,
        accountName: this.accountName
      })
      .then(account => {
        LOG.debug(account);
        this.loading = false;
        this.dialogController.ok(account.settings);
      })
      .catch(e => {
        LOG.error(e);
        this.loading = false;
      });
  }

  onCancel() {
    this.dialogController.cancel();
  }
}
