/** @format */
import {
  inject,
  observable
} from 'aurelia-framework';
import remindersService, {
  TimeIntervalUnits,
  ReminderStatus,
  ReminderSetConfiguration
} from 'services/api/remindersService';
import {
  TranslationService
} from 'services/translationService';

import './admin-reminders-page.scss';

@inject(TranslationService)
export class AdminRemindersList {
  @observable selectedType;

  constructor(_TranslationService) {
    this.ts = _TranslationService;
    //
    this.remindersService = remindersService;
    //

    this.gridHeaders = [{
        label: 'task'
      },
      {
        label: 'type',
        sortable: true
      },
      {
        label: 'status',
        sortable: true,
        sortField: 'statusPriority'
      }
    ];
    this.gridColumns = [{
        type: 'remindertask',
      },
      {
        type: 'text',
        field: 'type',
        translate: true
      },
      {
        type: 'text',
        field: 'status',
        translate: true,
        cssClass: 'reminder-status',
        cssClassField: 'status'
      }
    ];

    this.sortProperties = {
      field: 'statusPriority',
      reverse: true
    }

    this.reminders = [];
    this.filter = {};
    this.selectedType = null;
    this.task = '';

    this.loading = false;

    this.onReminderSelected = this.onReminderSelected.bind(this);
    this.onFilterApply = this.onFilterApply.bind(this);
  }

  activate(params, config) {
    this.route = config;
    this.router = this.route.navModel.router;
  }

  onFilterApply() {
    this.filterToObject();
    this.loadReminders();
  }

  filterToObject() {
    this.filter = {};
    if (this.selectedType) {
      this.filter.type = (this.selectedType) ? this.selectedType : null;
    }
  }

  selectedTypeChanged() {
    this.onFilterApply();
  }

  loadReminders() {
    this.loading = true;
    this.remindersService.getRemindersSets(this.filter).then(reminders => {
      this.reminders = reminders;
      this.loading = false;
    });
  }

  onReminderSelected(reminder) {
    let route = 'admin-reminders-set';
    this.goToDetails(reminder, route);
  }

  newReminderSet() {
    let route = 'admin-reminder-details';
    this.goToDetails(new ReminderSetConfiguration({
      type: 'service',
      settings: {
        isRecurring: true,
      },
      owner: {
        type: 'everyone'
      },
      context: {
        ids: [],
        type: 'assets'
      }
    }), route);
  }

  goToDetails(reminderSet, route) {
    this.router.routes.find(x => x.name === route).settings = {
      reminderSet
    };
    this.router.navigateToRoute(route, {
      id: reminderSet.id || 'new'
    });
  }
}
