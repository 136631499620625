/** @format */
import {
  DialogService
} from 'aurelia-dialog';
import {
  inject,
  observable
} from "aurelia-framework";
import remindersService, {
  ReminderStatus,
  ReminderTypes
} from 'services/api/remindersService';
import {
  TranslationService
} from 'services/translationService';
import {
  ConvertUtils
} from '@fonix/web-utils';
import {
  ReminderDetails
} from 'components/reminder-details/reminder-details';
import {
  AddReminderDetails
} from 'components/reminder-details/add-reminder-details';
import {
  EditReminderDetails
} from 'components/reminder-details/edit-reminder-details';
import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';


import './admin-reminders-page.scss';

const ID_ALL = 0;

@inject(TranslationService, DialogService)
export class AdminRemindersSetList {

  @observable selectedStatus;

  constructor(_TranslationService, _DialogService) {
    this.ts = _TranslationService;
    this.dialogService = _DialogService;
    //
    this.remindersService = remindersService;
    this.accountUnit = ConvertUtils.getUnit('distance') || {
      name: 'km',
      weight: 0
    };
    //

    this.gridHeaders = [{
        label: ''
      },
      {
        label: 'asset',
        sortable: true
      },
      {
        label: 'status',
        sortable: true,
        sortField: 'statusPriority'
      },
      {
        label: 'due',
      }
    ];
    this.gridColumns = [{
        type: 'icon',
        field: 'icon',
        cssClass: 'icon'
      },
      {
        type: 'text',
        field: 'contextName'
      },
      {
        type: 'text',
        field: 'status',
        translate: true,
        cssClass: 'reminder-status',
        cssClassField: 'status'
      },
      {
        type: 'reminderdue',
        cssClass: 'reminder-due',
      }
    ];

    this.sortProperties = {
      field: 'statusPriority',
      reverse: true
    }

    this.allSelectableActions = [{
        name: 'delete',
        icon: 'trash',
        action: this.multiSelectDelete.bind(this)
      },
      {
        name: 'edit',
        icon: 'edit',
        action: this.multiSelectEdit.bind(this)
      },
      {
        name: 'mark_as_resolved',
        label: 'mark_as_resolved',
        dropdown: true,
        action: this.multiSelectResolved.bind(this)
      }
    ]

    this.multiSelect = true;
    this.multiSelectActions = this.allSelectableActions

    this.selectedStatus = null;
    this.reminderStatus = [{
      id: 'all',
      value: ID_ALL,
      order: 1,
      name: this.ts.getCap('unresolved'),
      disabled: false,
      default: false,
      selected: true
    }];

    var order = 2
    for (let status in ReminderStatus) {
      let item = ReminderStatus[status];
      this.reminderStatus.push({
        id: item.id,
        value: item.value,
        order: order,
        name: this.ts.getCap(item.id)
      });
      order++;
    }

    this.reminderSet = null;
    this.reminders = [];

    this.filter = {};

    this.loading = false;
    this.onReminderSelected = this.onReminderSelected.bind(this)
    this.onReminderStatusChanged = this.onReminderStatusChanged.bind(this);
  }

  activate(params, cfg) {
    this.route = cfg;
    this.router = this.route.navModel.router;
    let {
      reminderSet
    } = cfg.settings;
    if (!reminderSet) {
      this.navigateBack();
      return;
    }

    this.reminderSet = reminderSet;
    if (this.reminderSet.status == ReminderStatus.resolved.id) {
      this.reminderStatus = this.reminderStatus.map(s => {
        s.selected = (s.id == ReminderStatus.resolved.id) ? true : false;
        return s;
      })
      this.selectedStatus = ReminderStatus.resolved.value;
    }
    this.filterToObject();
  }

  loadReminders() {
    this.loading = true;
    this.remindersService.getReminders(this.filter).then(reminders => {
      this.reminders = reminders;
      this.loading = false;
    });
  }

  onReminderStatusChanged(status) {
    this.selectedStatus = (status && status.value != ID_ALL) ? status.value : null
    this.filterToObject();
  }

  selectedStatusChanged() {
    if (this.selectedStatus != ReminderStatus.resolved.id) {
      this.multiSelectActions = this.allSelectableActions
      this.multiSelect = true;
    } else {
      this.multiSelectActions = []
      this.multiSelect = false;
    }
  }

  filterToObject() {
    this.filter = {};
    if (this.selectedStatus) {
      this.filter.status = (this.selectedStatus) ? this.selectedStatus : null;
    }

    this.filter.setId = (this.reminderSet && this.reminderSet.id) ? this.reminderSet.id : null

    this.loadReminders();
  }

  onReminderSelected(reminder) {
    if (reminder) {
      this.dialogService
        .open({
          viewModel: ReminderDetails,
          model: {
            reminder: reminder,
            reminderSet: this.reminderSet
          }
        })
        .whenClosed(result => {
          if (!result.wasCancelled) {
            this.loadReminders();
          }
        });
    }
  }

  multiSelectDelete() {
    let selectedReminders = this.reminders.filter(reminder => (reminder.selected && !reminder.resolved))
    let selectedRemindersId = selectedReminders.map(reminder => reminder.id)
    if (selectedRemindersId.length) {
      this.openPrompt('delete_reminders', true, 'delete_reminders_message', 'delete').then(confirm => {
        if (!confirm) return;
        this.loading = true;
        this.remindersService
          .deleteReminders(selectedRemindersId, this.reminderSet.id)
          .then(() => {
            if (this.reminders.filter(reminder => (!reminder.selected)).length) {
              this.loading = false;
              this.loadReminders();
            } else {
              this.navigateBack();
            }
          })
          .catch(err => {
            this.loading = false;
            this.loadReminders();
          });
      });
    }
  }

  openPrompt(title, question, message, confirm, showCancel, showConfirm) {
    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm,
            showCancel,
            showConfirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }

  multiSelectResolved() {
    let selectedReminders = this.reminders.filter(reminder => (reminder.selected && !reminder.resolved))
    let selectedRemindersId = selectedReminders.map(reminder => reminder.id)
    if (selectedRemindersId.length) {
      this.openPrompt('resolve_reminders', true, 'resolve_reminders_message', 'resolve').then(confirm => {
        if (!confirm) return;
        this.loading = true;
        this.remindersService
          .updateMultipleReminderStatus(selectedRemindersId, {
            status: ReminderStatus.resolved.id
          })
          .then(() => {
            if (!this.reminders.filter(reminder => (!reminder.selected && reminder.status != ReminderStatus.resolved.id)).length) {
              this.reminderStatus = this.reminderStatus.map(s => {
                s.selected = (s.id == ReminderStatus.resolved.id) ? true : false;
                return s;
              })
              this.selectedStatus = ReminderStatus.resolved.value;
            }
            this.loading = false;
            this.filterToObject();
          })
          .catch(err => {
            this.loading = false;
            this.filterToObject();
          });
      });
    } else {
      this.reminders = this.reminders.filter(reminder => (reminder.selected)).map(r => r.selected == false)
    }
  }

  multiSelectEdit() {
    let selectedReminders = this.reminders.filter(reminder => (reminder.selected && !reminder.resolved))
    if (this.reminderSet.type == ReminderTypes.service && selectedReminders.length > 1) {
      this.openPrompt(null, false, 'bulk_edit_reminder_limit', null, false, true).then(confirm => {
        if (!confirm) return;
      });
    } else if (selectedReminders.length) {
      this.dialogService
        .open({
          viewModel: EditReminderDetails,
          model: {
            reminderSet: this.reminderSet,
            reminders: selectedReminders
          }
        })
        .whenClosed(result => {
          if (!result.wasCancelled) {
            this.loadReminders();
          }
        });
    }
  }

  newReminder() {
    this.dialogService
      .open({
        viewModel: AddReminderDetails,
        model: {
          reminderSet: this.reminderSet,
          reminders: this.reminders
        }
      })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          this.loadReminders();
        }
      });
  }

  goToDetails() {
    let route = 'admin-reminder-details';
    this.router.routes.find(x => x.name === route).settings = {
      reminderSet: this.reminderSet
    };
    this.router.navigateToRoute(route, {
      id: this.reminderSet.id || 'new'
    });
  }

  navigateBack() {
    setTimeout(() => this.router.navigateToRoute('admin-reminders'), 0);
  }
}
