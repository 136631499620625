import { bindable } from 'aurelia-framework';


const TYPES = {
  'email' : 'email'
};

export class ReminderConfigChannels {

  @bindable channels;
  @bindable disabled;

  constructor() {
    this.channels = [];
    this.disabled = false;

    this.emails = [];

    this.onEmailsChanged = this.onEmailsChanged.bind(this);
  }

  channelsChanged(newChannels,oldChannels) {
    this.emails = this.getChannel(TYPES.email).recipients
  }
  
  //update chanels by means of two way binding
  updateChannel(type, value) {
    this.getChannel(type).recipients = !!value.length ? value.split(',') : [];
  }

  getChannel(type) {
    this.channels = this.channels || [];
    let channel = this.channels.find(x => x.type === type);
    if(!channel) {
      channel = { type, recipients: [] };
      this.channels.push(channel);
    }
    return channel;
  }

  onEmailsChanged(emails) {
    this.updateChannel(TYPES.email, emails)
  }
}
