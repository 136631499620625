/** @format */

import poiService from 'services/api/areasService';
import { bindable } from 'aurelia-framework';

export class AdminPoiList {
  @bindable route;

  constructor() {
    this.poiService = poiService;

    this.loading = false;
    this.poi = [];
    this.route = {};
    this.selectedId = null;

    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.onItemSelect = this.onItemSelect.bind(this);
    this.onDetailsClose = this.onDetailsClose.bind(this);
  }

  attached() {
    this.loadPOI();
  }

  detached() {
    this.poi = [];
    this.selectedId = null;
  }

  loadPOI() {
    this.loading = true;
    this.poiService.get().then(poi => {
      this.poi = poi;
      this.loading = false;
    });
  }

  onItemSelect(poi) {
    this.goToDetails(poi);
  }

  goToDetails(poi) {
    this.selectedId = poi && poi.id;
  }

  onDetailsClose() {
    this.selectedId = null;
    this.loadPOI();
  }
}
