import { bindable } from 'aurelia-framework';

export class AdminPoiPage {
  @bindable route;

  constructor() {
    this.route = {};
    this.activeTab = 'admin-poi-list';
  }

  activate(params, config) {
    this.route = config;
  }

  changeTabs(event) {
    if(event.detail && event.detail.activeTab) {
      this.activeTab = event.detail.activeTab;
    }
  }
}